import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.config.productionTip = false;

import { $get, $post, $upload } from "@/utils/request";
Vue.prototype.$get = $get;
Vue.prototype.$post = $post;
Vue.prototype.$upload = $upload;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
