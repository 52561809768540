import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: "/evaluate",
    children: [
      {
        path: "/evaluate",
        name: "evaluate",
        component: () => import("@/views/evaluate.vue"),
      },
      {
        path: "/target",
        name: "target",
        component: () => import("@/views/target.vue"),
      },
      {
        path: "/factor",
        name: "factor",
        component: () => import("@/views/factor.vue"),
      },
      {
        path: "/segment",
        name: "segment",
        component: () => import("@/views/segment.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
