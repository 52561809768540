import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    task_id: '',
    left_id: "",
  },
  mutations: {
    changeTaskId(state, id) {
      state.task_id = id;
    },
    changeLeftId(state, id) {
      state.left_id = id;
    },
  },
});
